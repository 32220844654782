/*
 *@Author: 段彩翔
 *@Date: 2022-03-23 17:22:26
 *@Description: 产品详情
*/
<template>
	<div class='displayFlex flexColumn h100'>
		<NavbarVue color="white" backgroundColor="#FF9941" :showBack="true">Product Detail</NavbarVue>
		<!-- 产品文字介绍 左右布局  左侧图片 右侧文字-->
		<div class="displayFlex h50">
			<div class="w40">
				<img :src="product_img" alt="">
			</div>
			<!-- 标题、描述 -->
			<div class="w60 p20px">
				<h2>{{product_name}}</h2>
				<h1 class="mb40px">{{product_line_name}}</h1>
				<span>{{product_description}}</span>
			</div>
		</div>
		<!-- 产品tabs页 -->
		<div class="h50">
			<el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
				<el-tab-pane label="Intro" name="Intro">
					<div v-for="(item, index) in product_intro" :key="index" class="textStyle">
						{{index+1}}. {{item}}
					</div>
				</el-tab-pane>
				<el-tab-pane label="Liabilities" name="Liabilities">
					<div v-for="(item, index) in product_liabilities" :key="index" class="textStyle">
						{{index+1}}  {{item}}
					</div>
				</el-tab-pane>
				<el-tab-pane label="Media" name="Media">
					<div v-for="(item, index) in product_media" :key="index" class="textStyle">
						{{index+1}}. {{item}}
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../../components/Navbar.vue";
import { productDetailQuery } from '../../api/Product'
export default {
	components: { NavbarVue },
	data() {
		return {
			activeName: 'Intro',
			product_liabilities: [],
			product_intro: [],
			product_media: [],
			product_name: '',
			product_description: '',
			product_img: '',
			product_line_name: ''
		};
	},
	computed: {},
	watch: {},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
		getMessage() {
			let code = this.$router.currentRoute.query.code
			if (code) {
				productDetailQuery({ infinitum_product_code: code }).then(res => {
					let {product_name, product_intro, product_media, product_liabilities, product_description, product_img, product_line_name } = {...res.content}
					this.product_name = product_name
					this.product_intro = product_intro
					this.product_media = product_media
					this.product_liabilities = product_liabilities
					this.product_description = product_description
					this.product_img = product_img
					this.product_line_name = product_line_name
				})
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.getMessage()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
	},
	beforeCreate() { }, //生命周期 - 创建之前
	beforeMount() { }, //生命周期 - 挂载之前
	beforeUpdate() { }, //生命周期 - 更新之前
	updated() { }, //生命周期 - 更新之后
	beforeDestroy() { }, //生命周期 - 销毁之前
	destroyed() {}, //生命周期 - 销毁完成
	activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.textStyle {
	border-bottom: 1px solid #0b0b0b33;
	height: 50px;
    line-height: 50px;
	padding-left: 20px;
	color: #646464;
}
.el-tabs >>>.el-tabs__content {
	padding: 20px;
}
.el-tabs >>>.el-tabs__item:hover, .el-tabs >>>.el-tabs__item.is-active {
	color: #303133;
    font-weight: 600;
}
h2 {
	font-size: 32px;
	color: #646464;
	margin-bottom: 20px;
}
h1 {
	font-size: 42px;
	color: #333333;
	text-align: center;
}
span {
	line-height: 28px;
	font-size: 20px;
	color: #646464;
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}
</style>